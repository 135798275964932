<template>
  <v-dialog v-bind="$attrs" v-on="$listeners" v-model="show" :max-width="maxWidth" @mouseup.prevent="unFocus">
    <v-card id="adsDialogComponent" @mouseup.prevent="unFocus">
      <FocusTrap :isActive="focusItems[0]" :returnFocusOnStop="false">
        <div ref="closeButton" class="close-btn__wrapper">
          <v-btn
            @keydown.tab="tabKeyDown($event, 0, true, true)"
            v-if="displayCloseBtn"
            icon
            @click="show = false"
            aria-label="close"
          >
            <v-icon> close </v-icon>
          </v-btn>
        </div>
      </FocusTrap>
      <v-card-title class="mb-5 pt-6 pr-3 pl-6 d-flex justify-space-between">
        <h2 class="title primary-blue--text">School information</h2>
      </v-card-title>
      <v-card v-if="selectedSchool" class="mt-3 pa-3 d-flex flex-wrap justify-space-between" flat>
        <div class="pr-4 pl-4 pb-3 grow" style="flex-basis: 40%">
          <div>
            <GmapStreetViewPanorama
              :options="{
                addressControl: false,
                fullscreenControl: false,
                linksControl: false,
                // zoomControl: false,
                clickToGo: false,
                // panControl: false,
                // disableDefaultUI: true,
              }"
              :position="{ lat: selectedSchool?.lat, lng: selectedSchool?.lng }"
              :pov="{ heading: 70, pitch: 0 }"
              :style="` ${mobile ? 'width: 100%; height: 200px' : 'width: 410px; height: 200px'}`"
              :visible="true"
              :zoom="2"
            />
          </div>
          <div class="d-flex flex-column mt-2 justify-space-between">
            <h3
              :class="`pt-2 pl-0 default-text font-bold title-text primary-blue--text ${mobile ? 'mobile-label' : ''}`"
            >
              {{ selectedSchool?.schoolFullName }}
            </h3>
            <div v-if="selectedSchool?.isIncentive || selectedSchool?.isCC" class="d-flex flex-column pt-1">
              <div v-if="selectedSchool?.isIncentive" class="align-start">
                <Chip
                  :color="COLOURS.INCENTIVE.background"
                  small
                  text="INCENTIVE SCHOOL"
                  :textColor="COLOURS.INCENTIVE.text"
                >
                  <span :class="`${mobile ? 'mobile-text' : 'default-text'} font-weight-bold`"> INCENTIVE SCHOOL </span>
                </Chip>
              </div>
              <div v-if="selectedSchool?.isCC" class="align-start pt-1">
                <Chip :color="COLOURS.CC.background" small text="CC SCHOOL" :textColor="COLOURS.CC.text">
                  <span :class="`${mobile ? 'mobile-text' : 'default-text'} font-weight-bold`">
                    CONNECTED COMMUNITY SCHOOL
                  </span>
                </Chip>
              </div>
            </div>
          </div>
          <div class="d-flex text-left align-center pt-2 pb-0 pl-0">
            <div :class="`${mobile ? 'mobile-label' : 'label-text'}  font-weight-bold`" style="width: 40%">
              School code:
            </div>
            <div :class="`pl-5 ${mobile ? 'mobile-text' : 'default-text'}`">
              {{ selectedSchool?.schoolCode }}
            </div>
          </div>
          <div class="d-flex text-left align-center pt-2 pb-0 pl-0">
            <div :class="`${mobile ? 'mobile-label' : 'label-text'}  font-weight-bold`" style="width: 40%">
              Total enrolments:
            </div>
            <div :class="`pl-5 ${mobile ? 'mobile-text' : 'default-text'}`">
              {{ selectedSchool?.studentNumber }}
            </div>
          </div>
          <v-divider class="ma-4"></v-divider>
          <FocusTrap :isActive="focusItems[1]" :returnFocusOnStop="false">
            <div>
              <div @keydown.tab="tabKeyDown($event, 1, true)" class="d-flex text-left pt-2 pl-0">
                <FeatureIcon backgroundColor="#ebebeb" color="#407EC9" icon="mdi-map-marker-outline" size="28px" />
                <div :class="`pl-3 align-self-center ${mobile ? 'mobile-label' : 'label-text'}`">
                  <a
                    @mousedown.prevent="() => {}"
                    :href="`${googleMapsUrl}${
                      selectedSchool?.lat
                        ? `${selectedSchool?.lat},${selectedSchool?.lng}`
                        : addNSWBeforePostCode(selectedSchool?.address)
                    }`"
                    :aria-label="`Address: ${addNSWBeforePostCode(selectedSchool?.address)}`"
                    target="_blank"
                  >
                    {{ addNSWBeforePostCode(selectedSchool?.address) }}
                  </a>
                </div>
              </div>
              <div class="d-flex text-left pt-3 pl-0">
                <FeatureIcon backgroundColor="#ebebeb" color="#407EC9" icon="mdi-phone-outline" size="28px" />
                <div :class="`pl-3 align-self-center ${mobile ? 'mobile-label' : 'label-text'}`">
                  <a
                    @mousedown.prevent="() => {}"
                    :href="`tel:${selectedSchool?.phone}`"
                    :aria-label="`Phone number: ${selectedSchool?.phone}`"
                    >{{ selectedSchool?.phone }}</a
                  >
                </div>
              </div>
              <div @keydown.tab="tabKeyDown($event, 1, false, true)" class="d-flex text-left pt-3 pl-0">
                <FeatureIcon backgroundColor="#ebebeb" color="#407EC9" icon="mdi-email-outline" size="28px" />
                <div :class="`pl-3 align-self-center ${mobile ? 'mobile-label' : 'label-text'}`">
                  <a
                    @mousedown.prevent="() => {}"
                    :href="`mailto:${selectedSchool?.email}`"
                    :aria-label="`Email: ${selectedSchool?.email}`"
                    >{{ selectedSchool?.email }}</a
                  >
                </div>
              </div>
            </div>
          </FocusTrap>
        </div>
        <div :class="`pa-3 pl-4 ${mobile ? 'pt-5' : 'pt-0'}  grow`" style="flex-basis: 60%">
          <div class="d-flex text-left justify-content-between grow">
            <div class="flex-column grow">
              <h3 class="title primary-blue--text">Benefits and allowances</h3>
              <div :class="`${mobile ? 'mobile-label' : 'label-text'}`">
                {{ selectedSchool?.transferPoints }}
                transfer points
              </div>
            </div>
            <div :class="`px-5  ${mobile ? 'mobile-label text-right' : 'label-text align-self-start'}`">
              Total major benefits up to
              <div :class="`title text-right ${mobile ? 'mobile-label' : ''}`">
                ${{ selectedSchoolBenefits?.total.toLocaleString() }}
              </div>
            </div>
          </div>
          <p class="my-3 body-2">
            <strong>Please note</strong> that the values provided by the calculator are indicative only.
          </p>
          <FocusTrap :isActive="focusItems[2]" :returnFocusOnStop="true">
            <div class="mt-5">
              <v-card class="pb-1">
                <v-list class="pa-0" :key="`BenefitsList-${index}`" v-for="(expansionItem, index) in expansionItems">
                  <v-list-item class="d-flex justify-space-between py-5" style="background: #e2e2e2">
                    <div :class="`text-left font-weight-bold grow ${mobile ? 'mobile-label' : ''}`">
                      {{ expansionItem.title }}
                    </div>
                  </v-list-item>
                  <v-list-item class="d-flex justify-space-between font-weight-bold primary-blue--text">
                    <div
                      :class="`text-left ${mobile ? 'mobile-label' : ''}`"
                      :style="`width: ${mobile ? '55%' : '65%'}`"
                    >
                      Benefit
                    </div>
                    <div
                      v-if="
                        expansionItem.category === BENEFIT_CATEGORIES.major ||
                        expansionItem.category === BENEFIT_CATEGORIES.professional
                      "
                      :class="`text-center ${mobile ? 'mobile-label' : ''}`"
                      :style="`width: ${mobile ? '24%' : '14%'}`"
                    >
                      Included
                    </div>
                    <div
                      :class="`text-right ${mobile ? 'mobile-label' : ''}`"
                      :style="
                        expansionItem.category === BENEFIT_CATEGORIES.major ||
                        expansionItem.category === BENEFIT_CATEGORIES.professional
                          ? `width: 21%`
                          : `width: ${mobile ? '45%' : '35%'}`
                      "
                    >
                      Value
                    </div>
                  </v-list-item>
                  <v-divider />
                  <v-list-item
                    v-for="(benefitItem, benefitIndex) in findCategoryBenefits(
                      benefitDetails,
                      selectedSchoolBenefits,
                      expansionItem.category === BENEFIT_CATEGORIES.major ||
                        expansionItem.category === BENEFIT_CATEGORIES.professional,
                      expansionItem.category
                    )"
                    :key="`benefitItem-${index}-${benefitIndex}`"
                    class="d-flex justify-space-between py-5"
                    style="border-bottom: 1px solid #ebebeb"
                  >
                    <div :style="`width: ${mobile ? '55%' : '65%'} `" class="text-left">
                      <div :class="`${mobile ? 'mobile-label' : 'label-text'}`">
                        {{ benefitItem.name }}
                      </div>
                      <div
                        v-if="benefitItem.description"
                        :class="`pt-1 ${mobile ? 'mobile-text' : 'default-text'}`"
                        v-html="`${marked(benefitItem.description)}`"
                      ></div>
                    </div>
                    <div
                      v-if="
                        expansionItem.category === BENEFIT_CATEGORIES.major ||
                        expansionItem.category === BENEFIT_CATEGORIES.professional
                      "
                      :style="`width: ${mobile ? '24%' : '14%'}`"
                      class="text-center"
                    >
                      <v-icon v-if="benefitItem.value?.length" color="#00AA45"> check </v-icon>
                      <v-icon v-else color="#D7153A"> close </v-icon>
                    </div>
                    <div
                      :style="
                        expansionItem.category === BENEFIT_CATEGORIES.major ||
                        expansionItem.category === BENEFIT_CATEGORIES.professional
                          ? `width: 21%`
                          : `width: ${mobile ? '45%' : '35%'}`
                      "
                      class="text-right d-flex flex-column flex-nowrap"
                    >
                      <div :class="`${mobile ? 'mobile-label' : ''}`">
                        <span
                          v-if="benefitItem.valueDenomination?.includes('$')"
                          class="font-weight-bold"
                          v-html="`${benefitItem.valueDenomination}`"
                        />
                        <span
                          class="font-weight-bold"
                          v-html="benefitItem.value ? formatValue(benefitItem.value) : 0"
                        />
                        <span
                          v-if="benefitItem.valueDenomination === '%'"
                          class="font-weight-bold"
                          v-html="`${benefitItem.valueDenomination}`"
                        />
                        <span
                          v-else-if="!!benefitItem.valueDenomination && !benefitItem.valueDenomination?.includes('$')"
                          class="font-weight-bold"
                          v-html="
                            benefitItem.value > 1 || !benefitItem.value
                              ? ` ${benefitItem.valueDenomination}s`
                              : ` ${benefitItem.valueDenomination}`
                          "
                        />
                      </div>
                      <div
                        v-if="benefitItem.valueDescription"
                        :class="`default-text ${mobile ? 'mobile-text' : ''}`"
                        style="color: #495054"
                      >
                        {{ benefitItem.valueDescription }}
                      </div>
                    </div>
                  </v-list-item>
                  <div
                    v-if="expansionItem.category === BENEFIT_CATEGORIES.major"
                    class="d-flex grow pa-4 pt-5"
                    style="background: #f4f4f7"
                  >
                    <div :class="`text-left subtitle-1 grow total ${mobile ? 'mobile-label' : ''}`">Total</div>
                    <span :class="`text-right grow default-text ${mobile ? 'mobile-text' : ''}`" style="color: #495054">
                      up to
                      <span :class="`subtitle-1 total ${mobile ? 'mobile-label' : ''}`">
                        ${{ selectedSchoolBenefits?.total?.toLocaleString() }}
                      </span>
                    </span>
                  </div>
                  <AlertBox v-if="expansionItem.category === BENEFIT_CATEGORIES.major">
                    <div class="font-weight-bold default-text">As defined in the departmental policy</div>
                    <div class="font-weight-regular default-text">
                      Go to more
                      <a
                        @keydown.tab="tabKeyDown($event, 2, true)"
                        @mousedown.prevent="() => {}"
                        href="https://education.nsw.gov.au/teach-nsw/find-teaching-jobs/choose-rural/benefits-and-incentives"
                        target="_blank"
                        class="font-weight-bold primary-blue--text"
                        style="text-decoration: none"
                        >information about major financial benefits</a
                      >.
                    </div>
                  </AlertBox>
                  <AlertBox v-if="expansionItem.category === BENEFIT_CATEGORIES.professional">
                    <div class="font-weight-bold default-text">As defined in the departmental policy</div>
                    <div class="font-weight-regular default-text">
                      Go to more
                      <a
                        @mousedown.prevent="() => {}"
                        href="https://education.nsw.gov.au/teach-nsw/find-teaching-jobs/choose-rural/benefits-and-incentives"
                        target="_blank"
                        class="font-weight-bold primary-blue--text"
                        style="text-decoration: none"
                        >information about major financial benefits</a
                      >.
                    </div>
                  </AlertBox>
                  <AlertBox v-else-if="expansionItem.category === BENEFIT_CATEGORIES.additional">
                    <div class="font-weight-bold default-text">Outlined in industrial agreements</div>
                    <div class="font-weight-regular default-text">
                      Go to more
                      <a
                        @mousedown.prevent="() => {}"
                        href="https://education.nsw.gov.au/teach-nsw/find-teaching-jobs/choose-rural/benefits-and-incentives"
                        class="font-weight-bold primary-blue--text"
                        target="_blank"
                        style="text-decoration: none"
                        >information about additional allowances</a
                      >. Please note that some allowances may require teachers to complete a HR form. See
                      <a
                        @mousedown.prevent="() => {}"
                        @keydown.tab="tabKeyDown($event, 2, false, true)"
                        href="https://education.nsw.gov.au/inside-the-department/human-resources/pay-leave-and-benefits/benefits-overview/employee-forms"
                        target="_blank"
                        class="font-weight-bold primary-blue--text"
                        style="text-decoration: none"
                        >full list of HR forms</a
                      >.
                    </div>
                  </AlertBox>
                </v-list>
              </v-card>
            </div>
          </FocusTrap>
        </div>
      </v-card>
    </v-card>
  </v-dialog>
</template>
<script>
import { Chip, FeatureIcon, ADS_Colors, FocusTrap } from '@nswdoe/doe-ui-core';
import { BENEFIT_CATEGORIES, COLOURS } from '@/constants';
import { gmapApi as googleMapsApi } from 'vue2-google-maps';
import { mapGetters } from 'vuex';
import { marked } from 'marked';
import { addNSWBeforePostCode, findCategoryBenefits, formatValue } from '@/helpers/utils';
import AlertBox from '@/components/UI/AlertBox.vue';

export default {
  name: 'InfoDialog',
  components: {
    AlertBox,
    Chip,
    FeatureIcon,
    FocusTrap,
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: String,
      default: '675px',
    },
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    actions: {
      type: Array,
      default() {
        return [];
      },
    },
    displayCloseBtn: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
    btnClass: {
      type: String,
      default: '',
    },
    btnHeight: {
      type: String,
      default: '50',
    },
    returnHandler: {
      type: Function,
      default: (name) => {
        return name;
      },
    },
  },
  data() {
    return {
      ADS_Colors,
      BENEFIT_CATEGORIES,
      COLOURS: COLOURS,
      expansion: 0,
      expansionItems: [
        {
          title: 'Major financial benefits',
          category: BENEFIT_CATEGORIES.major,
        },
        {
          title: 'Professional benefits',
          category: BENEFIT_CATEGORIES.professional,
        },
        {
          title: 'Available additional allowances',
          category: BENEFIT_CATEGORIES.additional,
        },
      ],
      photo: '',
      googleMapsUrl: 'https://www.google.com.au/maps/dir//',
      focusItems: [false, false, false],
    };
  },
  computed: {
    ...mapGetters({
      map: 'mapStore',
      googleApi: 'getGoogle',
      formValues: 'formValues',
    }),
    ...mapGetters('moduleResults', ['results']),
    show: {
      get() {
        return this.openDialog;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    actionsList() {
      return this.actions;
    },
    google: googleMapsApi,
    streetViewImageUrl() {
      return `https://maps.googleapis.com/maps/api/streetview?size=480x167&location=${this.selectedSchool?.lat},${this.selectedSchool?.lng}&fov=80&heading=70&pitch=0&key=${process.env.VUE_APP_GOOGLE_MAPS_KEY}`;
    },
    selectedSchool() {
      return this.results?.selectedSchool;
    },
    benefitDetails() {
      return this.results?.benefitDetails;
    },
    selectedSchoolBenefits() {
      return this.results?.selectedSchool?.benefits ?? [];
    },
    photoUrl() {
      this.getGoogleMapsPhoto();
      return this.photo;
    },
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  methods: {
    marked,
    addNSWBeforePostCode,
    findCategoryBenefits,
    formatValue,
    tabKeyDown(event, current, first = false, last = false, disable = false) {
      const shiftKey = event.shiftKey;
      let next;
      switch (true) {
        case shiftKey && current === 0:
          next = this.focusItems.length - 1;
          break;
        case shiftKey && current > 0:
          next = current - 1;
          break;
        case !shiftKey && current === this.focusItems.length - 1:
          next = 0;
          break;
        case !shiftKey && current < this.focusItems.length - 1:
          next = current + 1;
          break;
      }
      if (disable) {
        this.focusItems = this.focusItems.map(() => {
          return false;
        });
      } else if ((shiftKey && first) || (!shiftKey && last)) {
        this.focusItems = this.focusItems.map((item, index) => {
          return index === next;
        });
      }
    },
    unFocus() {
      this.focusItems = [false, false, false];
    },
    async getGoogleMapsPhoto() {
      const request = {
        query: this.selectedSchool.schoolFullName,
        fields: ['name', 'photo', 'geometry'],
      };
      const service = new this.google.maps.places.PlacesService(this.map);
      let photoUrl = '';
      const callBack = (results, status) => {
        if (status === 'OK' && results && results.length > 0 && results[0].photos && results[0].photos.length > 0) {
          this.photo = photoUrl;
        } else this.photo = 'https://maps.gstatic.com/tactile/pane/default_geocode-1x.png';
      };
      await service.findPlaceFromQuery(request, callBack);
    },
    toTitleCase(str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss';
.total {
  font-weight: bold;
  color: black;
  font-size: 20px !important;
}

mobile-total {
  font-size: 16px !important;
}

.default-text {
  font-family: 'Public Sans', sans-serif;
  font-size: 14px;
}

.mobile-text {
  font-size: 12px !important;
}

.label-text {
  font-size: 16px !important;
}

.mobile-label {
  font-size: 14px !important;
}

.font-bold {
  font-weight: bold !important;
}

.v-card {
  position: relative;

  .close-btn__wrapper {
    position: absolute;
    top: 9px;
    right: 9px;
  }

  .v-card__title {
    padding: 24px 10px 0 24px;
    margin-bottom: 18px;
    color: $ads-primary-blue !important;

    .v-avatar {
      background-color: $ads-primary-teal !important;
      margin-right: 6px;

      i {
        color: $ads-primary-blue;
      }
    }
  }

  .content {
    padding: 0 24px;
    margin-bottom: 36px;
    font-size: 16px;
    line-height: 24px;
    color: $ads-navy !important;
    background-color: unset !important;
  }

  .v-card__actions {
    display: flex;
    justify-content: flex-end;
    padding: 0 24px 24px 24px !important;

    .v-btn {
      margin-left: 12px !important;
      padding: 0 16px;
      color: $ads-white;
    }
  }

  .v-btn:focus {
    outline: 3px auto -webkit-focus-ring-color;
  }
}

.theme--dark {
  .content,
  .v-card__title {
    color: $ads-light-10 !important;
    background-color: unset !important;
  }

  .v-card__title .v-avatar {
    background-color: $ads-info-blue-dark !important;
  }

  .buttonText {
    color: $ads-dark !important;
  }
}
</style>
